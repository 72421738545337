import { render, staticRenderFns } from "./MaterialCopyGrid.vue?vue&type=template&id=1ff721a2&scoped=true&"
import script from "./MaterialCopyGrid.vue?vue&type=script&lang=js&"
export * from "./MaterialCopyGrid.vue?vue&type=script&lang=js&"
import style0 from "./MaterialCopyGrid.vue?vue&type=style&index=0&id=1ff721a2&scoped=true&lang=css&"
import style1 from "../../../Styles/dx-iview.css?vue&type=style&index=1&lang=css&"
import style2 from "../../../../../../node_modules/bootstrap-icons/font/bootstrap-icons.css?vue&type=style&index=2&id=1ff721a2&scoped=true&lang=css&"
import style3 from "../../../../../../node_modules/@riophae/vue-treeselect/dist/vue-treeselect.css?vue&type=style&index=3&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ff721a2",
  null
  
)

export default component.exports