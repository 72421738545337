<template>
  <div class="row" v-if="!isWizardFlow">
    <div class="col-md-12 widget">
      <div class="widget-body" style="height:unset">
        <Row :gutter="20">
          <template v-if="isPromotionMode">
            <Row-Col span="5" class="promo-input">
              <i-input v-model="promotionObject.name" :disabled="true">
                <span slot="append">
                  <button class="btn btn-success btn-sm" @click="openPromoModal = true">
                    <i class="fa fa-cog"></i>
                  </button>

                  <promotion-configuration
                    :openPromoModalProp="openPromoModal"
                    :promoConfigToEdit="promotionObject"
                    :organicPromotions="organicPromotions"
                    :inCampaignLauncher="true"
                    :isReadOnlyMode="isLivePromotion"
                    @modalStateChanged="openPromoModal = $event"
                    @promoUpdated="updatePromotionsObj"
                  >
                  </promotion-configuration>
                </span>
              </i-input>
            </Row-Col>

            <Row-Col span="5" class="promo-input">
              <i-input value="Publisher - Facebook" :disabled="true">
                <span slot="append" >
                  <campaign-configuration
                    :defaultFormConfig="getDefaultFormConfig()"
                    :campaignConfigurations="getEnabledConfigs"
                    v-on:newCampaignConfigSaved="
                      updateCampaignConfigs($event)
                    "
                    v-on:campaignConfigModalOpen="onConfigModalOpen()"
                    :isReadOnlyMode="isLivePromotion"
                  ></campaign-configuration>
                </span>
              </i-input>
            </Row-Col>

            <Row-Col span="4" style="float: right;">
              <Row>
                <button
                  @click="showNextStep"
                  class="btn btn-success"
                  style="display: block; margin: auto;"
                  :disabled="isCreationFlow || !builtImageIdUrlMap"
                >
                  <span v-if="isLivePromotion">View Summary</span>
                  <span v-else>Save and Preview</span>
                  <span v-if="loadNext"><i class="fa fa-spinner fa-spin"></i></span>
                </button>
              </Row>
            </Row-Col>
          </template>

          <template v-if="!isPromotionMode">
            <Row-Col span="4" style="padding-right:0px">
              <Select
                v-if="deltax.mediaPlanId == 0 || !selectedMediaPlan.Name"
                placeholder="Select a Media Plan"
                v-model="selectedMediaPlan.Id"
                :transfer="true"
                :disabled="processing"
                filterable>
                <OptionGroup label="Live">
                  <Option v-for="(item, index) in newMediaPlanConfigs" :key="index" :value="item.Id">{{ item.Name }}</Option>
                </OptionGroup>
                <OptionGroup label="Ended">
                  <Option v-for="(item, index) in oldMediaPlanConfigs" :key="index" :value="item.Id">{{ item.Name }}</Option>
                </OptionGroup>
              </Select>
              <h4 v-if="deltax.mediaPlanId > 0 && selectedMediaPlan.Name">{{ selectedMediaPlan.Name }}</h4>
            </Row-Col>
            <Row-Col span="1" class="cl-pull-left">
              <direct-launch></direct-launch>
            </Row-Col>
            <Row-Col span="4">
              <Select
                placeholder="Publisher"
                v-model="campaignLauncherPublisher.selected"
                :transfer="true"
              >
                <Option
                  v-for="item in campaignLauncherPublisher.options"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </Option>
              </Select>
            </Row-Col>
            <Row-Col span="3">
              <line-item-configuration
                v-on:updatedMappedLineItems="setMappedLineItemsCount($event)"
                ref="lineItemConfig"
                @additionalInfo="getAdditionalInfoFromMapper"
                :key="rerenderLineItemConfiguration"
                :getAudiences="deltax.getAudiencesURL"
                :createTarget="deltax.createTargetURL"
                :targets="accountTargets"
                :selectedMediaPlanId="selectedMediaPlan.Id"
                :fromMediaPlan="false"
              ></line-item-configuration>
            </Row-Col>
            <Row-Col span="4">
              <Select
                placeholder="Campaign Configuration"
                v-model="selectedCampaignLauncherConfiguration.id"
                :transfer="true"
                filterable
                :disabled="!mediaPlanId || mediaPlanId == 0"
              >
                <Option
                  v-for="item in selectedConfigs"
                  :key="item.Id"
                  :value="item.Id"
                  :disabled="item.isAssetClaimRequired && selectedPublisher == publisher.Facebook"
                >{{ item.Name }}</Option>
              </Select>
            </Row-Col>
            <Row-Col span="5">
              <Row>
                <Row-Col span="20" class ="lineItemCountAndBudget">
                {{getMappedLineItemsCountAndBudget}}
                </Row-Col>
              </Row>
            </Row-Col>

            <Row-Col span="2" style="float: right;">
              <Row>
                <button
                  @click="showNextStep"
                  class="btn btn-success"
                  :class="{
                    disabled:
                      selectedCampaignLauncherConfigId == 0 ||
                      mappedLineItems.length == 0 ||
                      loadNext ||
                      AmsLoadNext ||
                      !isMolocoAndAdsheetLoaded
                  }"
                  style="display: block; margin: auto;"
                >
                  Next
                  <span v-if="loadNext"><i class="fa fa-spinner fa-spin"></i></span>
                </button>
              </Row>
            </Row-Col>

            <Row-Col span="1" style="float: right;">
              <Row>
                <button
                 v-if="isSheetSaveSupported()"
                  @click="saveCreative"
                  class="btn btn-success"
                  :class="{
                    disabled:
                      selectedCampaignLauncherConfigId == 0 ||
                      mappedLineItems.length == 0 ||
                      loadNext ||
                      AmsLoadSave ||
                      !isMolocoAndAdsheetLoaded
                  }"
                  style="display: block; margin: auto;"
                >
                  Save
                  <span v-if="saving"><i class="fa fa-spinner fa-spin"></i></span>
                </button>
              </Row>
            </Row-Col>
          </template>
        </Row>
      </div>
    </div>
  </div>
</template>
<script>
import { Select, Option, Row, Col, Input, OptionGroup } from "iview";
import LineItemConfiguration from "./LineItemConfiguration.vue";
import DirectLaunch from "./DirectLaunch.vue";
import { APIService } from "./../ApiService.js";
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import { EventBus } from "../EventBus.js";
import CampaignConfiguration from "./CampaignConfiguration.vue";
import PromotionConfiguration from "../../Promotion/Components/PromotionConfiguration.vue";
import FacebookCampaignLauncherConfig from "../Mixins/FacebookCampaignLauncherConfig.js";
import { adwordsEnums } from '../Constants/AdwordsEnums';
import { EventBus as GlobalEventBus } from "../../../EventBUs/EventBus.js";
import { getAssetRequirements } from "../Constants/AdwordsAdsheetSettingsRequirements"

export default {
  components: {
    Select,
    Option,
    LineItemConfiguration,
    CampaignConfiguration,
    PromotionConfiguration,
    Row,
    "Row-Col": Col,
    'i-input': Input,
    DirectLaunch,
    OptionGroup
  },
  props: {
    mediaPlan: { type: Object, default: () => {} },
    mediaPlans: { type: Array, default: () => [] },
    submissionId: 0,
    allSubmissions: { type: Array, default: () => [] },
    campaignConfigId: 0
  },
  mixins: [FacebookCampaignLauncherConfig],
  data () {
    return {
      processing: false,
      organicPromotions: [],
      targetLabels: [],
      campaignLauncherPublisher: {
        options: [],
        selected: 5
      },
      openConfigurationModal: false,
      selectedCampaignLauncherConfiguration: {
        id: "",
        config: this.getDefaultFormConfig()
      },
      lineItemConfiguration: {
        selected: "",
        options: ["Test"]
      },
      selectedMediaPlan: {},
      submission: {
        selectedId: 0
      },
      accountTargets: [],
      selectedConfigs: [],
      loadNext: false,
      saving: false,
      rerenderLineItemConfiguration: 0,
      openPromoModal: false,
      configSetForMediaPlanId: undefined
    };
  },
  async created () {
    let repsonse = await APIService.getOrganicPromotions(2);
    this.organicPromotions = repsonse.data.data;
  },
  mounted () {
    if (!this.$store.state.dbLocations) {
      APIService.getLocations()
        .then(response => {
          this.$store.commit("set_dbLocations", response.data.data)
        })
    }
    this.campaignLauncherPublisher.options = this.publisherOptions;
    this.selectedMediaPlan = this.mediaPlan;
    this.selectedCampaignLauncherConfiguration.Id = this.campaignConfigId;
    this.lineItemConfiguration.selected = "0 line item(s) selected"
    EventBus.$on("campaignLauncherApp-updateMediaPlanIdFromUrl", data => {
      this.$nextTick(function () {
        this.selectedMediaPlan.Id = data.Id;
        this.selectedMediaPlan.Name = data.Name;
      })
    });
    EventBus.$on("campaignLauncherApp-updatePublisherIdFromUrl", data => {
      this.$nextTick(function () {
        this.campaignLauncherPublisher.selected = parseInt(data);
        this.set_selectedPublisher(this.campaignLauncherPublisher.selected);
      })
    });
    EventBus.$on("campaignLauncherApp-updateConfigIdFromUrl", data => {
      this.$nextTick(function () {
        this.selectedCampaignLauncherConfiguration.id = parseInt(data);
      })
    });
  },
  beforeDestroy () {
    EventBus.$off("campaignLauncherApp-updateMediaPlanIdFromUrl");
    EventBus.$off("campaignLauncherApp-updatePublisherIdFromUrl");
    EventBus.$off("campaignLauncherApp-updateConfigIdFromUrl");
  },
  computed: {
    ...mapState([
      "accounts",
      "deltax",
      "businessProfilesSearchEngineId",
      "businessProfileId",
      "campaignLauncherConfigurations",
      "mediaPlanLineItems",
      "campaignLauncherConfigs",
      "campaignLauncherConfigBpseIdMapper",
      "publishedCreativesByCampaignConfigId",
      "mediaPlanId",
      "selectedCampaignLauncherConfigId",
      "AmsLoadNext",
      "AmsLoadSave",
      "mappedLineItems",
      "selectedPublisher",
      "isMediaPlanDataLoaded",
      "isPromotionMode",
      "promotionObject",
      "selectedCampaignLauncherConfig",
      "isCreationFlow",
      "builtImageIdUrlMap",
      "bpCurrencySymbol",
      "publisherOptions",
      "autoNavigateToNextStep",
      "publisher",
      "labelDimensions",
      "isWizardFlow",
      "tableData",
      "adFormats"
    ]),
    ...mapGetters(["isLivePromotion", "isFullIntegration", "getFacebookNomenclature"]),
    getMappedLineItemsCountAndBudget () {
      let lineItems = this.mediaPlanLineItems;
      let mappedLineItems = [];
      let mappedLineItemsCount = 0;
      var lineItemId = [];
      var budget = 0;
      lineItems.forEach(element => {
        if (
          (element.isLineItemMapped ||
          (element.config !== "" && this.selectedPublisher == this.publisher.DV360)) &&
          element.config === this.selectedCampaignLauncherConfiguration.id
        ) {
          mappedLineItems.push(element);
          if (!lineItemId.includes(element.id)) {
            budget += parseFloat(element.budget);
          }
          lineItemId.push(element.id)
        }
      });
      this.set_mappedLineItems(mappedLineItems);
      mappedLineItemsCount = Array.from(new Set(mappedLineItems.map(e => e.id))).length;
      var displayInfo = ""
      if (mappedLineItems && mappedLineItemsCount > 0) {
        if (mappedLineItemsCount == 1) {
          displayInfo = mappedLineItemsCount + " line item (Budget " + this.bpCurrencySymbol + budget + ")";
        } else {
          displayInfo = mappedLineItemsCount + " line items (Budget " + this.bpCurrencySymbol + budget + ")";
        }
      }
      return displayInfo;
    },
    getEnabledConfigs () {
      return this.campaignLauncherConfigurations.filter(
        config =>
          config.Status && config.searchEngineTypeId == this.selectedPublisher
      );
    },
    newMediaPlanConfigs () {
      var liveMediaPlans = this.mediaPlans.filter(x => new Date(x.EndDate).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0));
      return liveMediaPlans;
    },
    oldMediaPlanConfigs () {
      var oldMediaPlans = this.mediaPlans.filter(x => new Date(x.EndDate).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0));
      return oldMediaPlans;
    },
    isMolocoAndAdsheetLoaded () {
      if (this.selectedPublisher == this.publisher.Moloco) {
        return this.tableData && Object.keys(this.tableData).length == this.adFormats.length
      }
      return true
    },
    isAdwordsPerformanceMaxCampaign () {
      return this.selectedCampaignLauncherConfig.campaign.type === adwordsEnums.campaignType['Performance Max'];
    },
    isAdwordsSearchCampaign () {
      return this.selectedCampaignLauncherConfig.campaign.type === adwordsEnums.campaignType['Search'];
    }
  },
  methods: {
    ...mapMutations([
      "SET_BPID",
      "set_mappedLineItems",
      "SET_BPSEID",
      "set_campaignLauncherConfigurations",
      "set_selectedCampaignLauncherConfigId",
      "SET_selectedFbObjective",
      "set_publishedCreatives",
      "set_creativeFields",
      "SET_SHOW_PREVIEW",
      "set_selectedCampaignLauncherConfig",
      "set_selectedPublisher",
      "set_promotionObject",
      "set_mediaPlanId",
      "set_selectedMediaPlan",
      "set_selectedObjectiveIdForPublisher",
      "set_placementCustomizationSettings",
      "set_dynamicCreativeSettings",
      "set_lineItemLabels",
      "set_publishedCreativesByCampaignConfigId",
      "set_macroValueMapper",
      "set_adSheetCustomizationSettings",
      "set_createSheetCreatives",
      "set_totalSheetCreatives",
      "set_allCreativesInfo",
      "set_publishedSheetCreatives",
      "set_adCreativeMapping",
      "set_existingPostAds",
      "set_publishedCreativesInitialState",
      "set_campaignLauncherConfigBpseIdMapper",
      "set_adWordsAdSheetSettings"
    ]),
    ...mapActions(["getConversionActions"]),
    isSheetSaveSupported () {
      return [this.publisher.Facebook, this.publisher.Twitter, this.publisher.LinkedIn, this.publisher.DV360, this.publisher.Adwords, this.publisher.AMS.SponsoredProducts, this.publisher.AMS.SponsoredDisplay, this.publisher.Moloco].includes(this.selectedPublisher)
    },
    setMappedLineItemsCount (config) {
      this.set_mappedLineItems(config.mappedLineItems);
      this.lineItemConfiguration.selected =
        config.mappedLineItems.count + " line item(s) selected";
    },
    updateCampaignConfigs (r) {
      /**
       * campaignLauncherConfigurations = [{ Id: ConfigId, Name: configName }]
       * campaignLauncherConfigs = {
       *    configId1: {
       *        config: selectedCLConfiguration.config
       *    },
       *    configId2: ...
       * }
       */
      let campaignConfigs = this.campaignLauncherConfigurations;
      if (r.isNewConfig) {
        campaignConfigs.unshift(r.newCampaignConfig);
      } else {
        let index = campaignConfigs.findIndex(
          obj => obj.Id == r.newCampaignConfig.Id
        );
        Object.assign(campaignConfigs[index], {
          BpseId: r.newCampaignConfig.BusinessProfiles_SearchEngineId,
          Name: r.newCampaignConfig.Name,
          UpdatedAt: r.newCampaignConfig.UpdatedAt,
          UpdatedByUserId: r.newCampaignConfig.UpdatedByUserId,
          ConfigJson: r.newCampaignConfig.ConfigJson
        });
      }
      // set store settings
      this.campaignLauncherConfigBpseIdMapper[r.newCampaignConfig.Id] = {
        BpseId: r.newCampaignConfig.BusinessProfiles_SearchEngineId
      };
      this.campaignLauncherConfigs[r.newCampaignConfig.Id] = {
        config: r.newCampaignConfig.ConfigJson,
        searchEngineTypeId: this.selectedPublisher
      };
      this.set_campaignLauncherConfigurations(campaignConfigs);
      // if (r.continueCreating) {
      //   this.selectedCampaignLauncherConfiguration.id = "";
      //   this.selectedCampaignLauncherConfiguration.config = this.getDefaultFormConfig();
      // }
    },
    onConfigModalOpen () {
      let selectedConfigId = this.selectedCampaignLauncherConfiguration.id;
      if (!selectedConfigId) { return; }
      this.selectedCampaignLauncherConfiguration.config = JSON.parse(
        this.campaignLauncherConfigs[selectedConfigId].config
      );
    },
    getAdditionalInfoFromMapper (args) {
      this.selectedConfigs = args.configs;
      this.$store.commit("set_mediaPlanConfigs", this.selectedConfigs)
      if (this.selectedConfigs == 1) {
        // auto select config if there is only one
        this.$set(this.selectedCampaignLauncherConfiguration, "id", this.selectedConfigs[0].Id);
      }
      EventBus.$emit("launchSettings-mappedConfigsLoaded", this.selectedConfigs);
    },
    onLoadNextComplete () {
      this.loadNext = false;
    },
    showNextStep () {
      this.loadNext = true;
      EventBus.$emit("showNextStepClicked", this.onLoadNextComplete);
    },

    onSaveComplete () {
      this.saving = false;
    },
    saveCreative () {
      this.saving = true;
      EventBus.$emit("saveCreativeClicked", this.onSaveComplete);
    },

    updatePromotionsObj ({ newPromoObj }) {
      this.set_promotionObject(newPromoObj)
    },
    getMediaPlanDetails (id) {
      if (id > 0) {
        let allMediaPlans = this.mediaPlans;
        let selectedMediaPlan = allMediaPlans.find(function (element, index) {
          if (element.Id === id) { return true; }
        });
        return { id, name: selectedMediaPlan.Name }
      }
    },
    showAdSheetLoader () {
      this.$store.commit("set_showAdSheetLoader", true)
      setTimeout(() => {
        this.$store.commit("set_showAdSheetLoader", false)
      })
    },
    setPublisherLevelInfo () {
      switch (this.selectedPublisher) {
        case this.publisher.Facebook:
          let isFacebookCampaignPresent = ((this.selectedCampaignLauncherConfiguration || {}).config || {}).campaign;
          if (isFacebookCampaignPresent) {
            var facebookObjectiveId = this.selectedCampaignLauncherConfiguration.config.campaign.objective;
            this.SET_selectedFbObjective(facebookObjectiveId);
            // setting ad formate for RNF
            if (this.selectedCampaignLauncherConfiguration.config.campaign.buyType == 4) { this.set_creativeFields(this.selectedCampaignLauncherConfiguration.config.adset.adformat) }
          }
          break;
        case this.publisher.LinkedIn:
          let isLinkedInConfigPresent = (this.selectedCampaignLauncherConfiguration || {}).config;
          if (isLinkedInConfigPresent) {
            var linkedInObjectiveId = this.selectedCampaignLauncherConfiguration.config.objective;
            this.set_selectedObjectiveIdForPublisher(linkedInObjectiveId);
          }
          break;
        case this.publisher.Twitter:
          let isTwitterConfigPresent = ((this.selectedCampaignLauncherConfiguration || {}).config || {}).campaign
          if (isTwitterConfigPresent) {
            var twitterObjectiveId = this.selectedCampaignLauncherConfiguration.config.campaign.objective;
            this.set_selectedObjectiveIdForPublisher(twitterObjectiveId);
          }
          break;
        case this.publisher.Moloco:
          let isMolocoConfigPresent = ((this.selectedCampaignLauncherConfiguration || {}).config || {}).campaign
          if (isMolocoConfigPresent) {
            var molocoObjectiveId = this.selectedCampaignLauncherConfiguration.config.campaign.objective;
            this.set_selectedObjectiveIdForPublisher(molocoObjectiveId);
          }
          break;
      }
    },
    async GetPublishedCreatives (configId) {
      var publishedCreativeResponse = await APIService.getApiData(
        this.deltax.apiEndPoint +
          "businessProfiles/" +
          this.businessProfileId +
          "/campaignLauncherConfigurations/GetPublishedCreatives" +
          "?mediaPlanId=" +
          this.$store.state.mediaPlanId +
          "&configId=" +
          configId
      )
      return publishedCreativeResponse;
    },
    isNomenclatureEnabled () {
      let facebookMacros = this.getFacebookNomenclature;
      if (facebookMacros && facebookMacros.Campaign != '') {
        return true;
      }
      return false;
    }
  },
  watch: {
    "selectedCampaignLauncherConfigId": function (value) {
      if (this.$router.currentRoute.path == "/summary") {
        this.selectedCampaignLauncherConfiguration.id = this.selectedCampaignLauncherConfigId
      }
    },
    "selectedCampaignLauncherConfiguration.id": async function (id) {
      this.$store.commit("set_editSheetLoadProgress", 0)
      if (id == "" || id == undefined || id == 0) {
        this.SET_BPSEID(0);
        this.SET_selectedFbObjective(null);
        this.set_selectedObjectiveIdForPublisher(null);
        this.set_selectedCampaignLauncherConfigId(0);
        this.$store.commit("set_editSheetLoadProgress", 0)
        this.set_publishedCreatives({});
        return;
      }
      this.set_placementCustomizationSettings(null);
      this.set_dynamicCreativeSettings(null);
      this.selectedCampaignLauncherConfiguration.config = JSON.parse(
        this.campaignLauncherConfigs[id].config
      );
      this.set_selectedCampaignLauncherConfig(
        JSON.parse(this.campaignLauncherConfigs[id].config)
      );
      if (this.$router.currentRoute.path != "/summary" || (this.$router.currentRoute.path == "/summary" && this.autoNavigateToNextStep)) {
        if (!this.campaignLauncherConfigBpseIdMapper[id] && this.selectedCampaignLauncherConfiguration.id == id) {
          let campaignLauncherConfigBpseIdMapperCopy = JSON.parse(JSON.stringify(this.campaignLauncherConfigBpseIdMapper));
          campaignLauncherConfigBpseIdMapperCopy[id] = { BpseId: Number(this.selectedCampaignLauncherConfiguration.config.bpseId) }
          this.set_campaignLauncherConfigBpseIdMapper(campaignLauncherConfigBpseIdMapperCopy)
        }
        this.SET_BPSEID(this.campaignLauncherConfigBpseIdMapper[id].BpseId);
        this.set_selectedCampaignLauncherConfigId(id);
        if (this.isFullIntegration) {
          this.setPublisherLevelInfo()
          if (this.isNomenclatureEnabled() && this.selectedPublisher == this.publisher.Facebook) {
            let selectedLineItems = this.mediaPlanLineItems.filter(li => li.config === id && li.isLineItemMapped === true);
            let lineItemIds = selectedLineItems.map(li => li.id).join(',');
            let targetIds = selectedLineItems.map(li => li.targets).join(',');
            APIService.getMacroValues(this.mediaPlanId, this.selectedCampaignLauncherConfigId, lineItemIds, targetIds)
              .then(response => {
                this.set_macroValueMapper(response.data);
              });
          }
          // if published creatives exist for selected config, set the table data
          if (this.selectedPublisher != this.publisher.Facebook && this.selectedPublisher != this.publisher.Moloco && !(this.selectedPublisher == this.publisher.Adwords && (this.isAdwordsPerformanceMaxCampaign || this.isAdwordsSearchCampaign))) {
            if (!this.publishedCreativesByCampaignConfigId[id] || (this.configSetForMediaPlanId != this.selectedMediaPlan.Id && !this.isPromotionMode)) {
              let response = await this.GetPublishedCreatives(id);
              let publishedCreatives = response.data;
              let publishedCreativesByCampaignConfig = {};
              publishedCreatives.forEach(e => {
                publishedCreativesByCampaignConfig[e.CampaignConfigId] = {
                  creativeJson: e.CreativeJson,
                  CreativeUpdatedAt: e.CreativeUpdatedAt ? (new Date(e.CreativeUpdatedAt)).addMinutes(-(new Date(e.CreativeUpdatedAt)).getTimezoneOffset()).toLocaleString() : 'No saved data',
                  editedCreativeJson: e.EditedCreativeJson
                };
              });
              this.set_publishedCreativesByCampaignConfigId(
                publishedCreativesByCampaignConfig
              );
              this.configSetForMediaPlanId = this.selectedMediaPlan.Id
            }
          }
          if (this.isPromotionMode || this.selectedPublisher != this.publisher.Facebook) {
            let creativeJson = this.publishedCreativesByCampaignConfigId[id] ? this.publishedCreativesByCampaignConfigId[id].creativeJson : "";
            let editedCreativeJson = this.publishedCreativesByCampaignConfigId[id] ? this.publishedCreativesByCampaignConfigId[id].editedCreativeJson : "";
            if (this.selectedPublisher == this.publisher.Adwords && creativeJson && creativeJson.handsonSpec) {
              this.set_publishedCreatives(JSON.parse(creativeJson.handsonSpec));
              this.set_createSheetCreatives(JSON.parse(creativeJson.handsonSpec));
              // set adsheet settings.
              let parsedHandsonSpec = JSON.parse(creativeJson.handsonSpec);
              if (parsedHandsonSpec && parsedHandsonSpec.adSheetSettings) {
                this.set_adWordsAdSheetSettings(parsedHandsonSpec.adSheetSettings);
              }
            } else if (creativeJson && typeof creativeJson === 'string') {
              this.set_publishedCreatives(JSON.parse(creativeJson));
              this.set_createSheetCreatives(JSON.parse(creativeJson));
              let parsedCreativeJson = JSON.parse(creativeJson);
              if (parsedCreativeJson.adSheetSettings && this.selectedPublisher == this.publisher.Adwords) {
                this.set_adWordsAdSheetSettings(parsedCreativeJson.adSheetSettings);
              }
              EventBus.$emit("adsheetWidget-configSwitched");
            } else {
              this.set_publishedCreatives({});
              this.set_createSheetCreatives({});
              this.set_totalSheetCreatives({});
              this.set_publishedSheetCreatives({});
              if (this.selectedPublisher == this.publisher.Adwords) {
                let assetTypeRequirements = getAssetRequirements(this.selectedCampaignLauncherConfig.campaign.type);
                let adsheetSettings = {};
                if (this.selectedCampaignLauncherConfig.campaign.type === adwordsEnums.campaignType['Demand Gen']) {
                  for (let assetType in assetTypeRequirements) {
                    if (typeof assetTypeRequirements[assetType] === "object") {
                      adsheetSettings[assetType] = {}
                      for (let subAssetType in assetTypeRequirements[assetType]) {
                        adsheetSettings[assetType][subAssetType] = assetTypeRequirements[assetType][subAssetType].min
                      }
                    }
                  }
                } else {
                  for (let item in assetTypeRequirements) {
                    adsheetSettings[item] = assetTypeRequirements[item].min;
                  }
                }
                this.set_adWordsAdSheetSettings(adsheetSettings);
              }
            }
            if (editedCreativeJson && typeof editedCreativeJson === 'string') {
              this.$store.commit("set_publishedCreativesInitialState", JSON.parse(editedCreativeJson))
            } else {
              this.$store.commit("set_publishedCreativesInitialState", {})
            }
          }
          if (this.campaignLauncherPublisher.selected == this.publisher.Adwords && this.selectedCampaignLauncherConfig.campaign.type == adwordsEnums.campaignType.Search) {
            this.getConversionActions({ businessProfileId: this.businessProfileId, businessProfilesSearchEngineId: this.selectedCampaignLauncherConfiguration.config.bpseId, type: adwordsEnums.conversionActionType.AdCall });
          }

          if (this.selectedPublisher != this.publisher.Facebook) {
            this.showAdSheetLoader();
          }
        }
        // automatically show second step, on the next tick so that everything is set in the UI. hacky but works
        if (
          [1, 5, 6, 7, 9, 45].includes(
            this.selectedPublisher
          ) &&
          this.autoNavigateToNextStep
        ) {
          this.$nextTick(function () {
            if (this.mediaPlanId > 0 && this.selectedPublisher && this.selectedCampaignLauncherConfigId) {
              this.showNextStep();
            }
          })
        }
      }
      if (this.selectedPublisher == this.publisher.Facebook && !this.isPromotionMode) {
        this.$store.commit("set_allCreativesInfo", {})
        this.$store.commit("set_publishedSheetCreatives", {})
        this.$store.commit("set_adCreativeMapping", [])
        this.set_existingPostAds([]);
      }
    },
    "selectedMediaPlan.Id": function () {
      this.selectedCampaignLauncherConfiguration.id = "";
      this.rerenderLineItemConfiguration ^= 1;
      var mediaPlanDetails = this.getMediaPlanDetails(this.selectedMediaPlan.Id)
      this.set_selectedMediaPlan(mediaPlanDetails)
    },
    "submission.selectedId": function (value) {
      this.$emit("submissionselect", value)
    },
    "campaignLauncherPublisher.selected": function () {
      this.set_selectedPublisher(this.campaignLauncherPublisher.selected);
      if (this.campaignLauncherPublisher.selected == 11) {
        APIService.getPublishData(0, 0, 10)
          .then(response => {
            this.$store.commit("set_AmsPublishedConfig", response.data.data[0].configIds)
          })
          .catch(() => {
            this.$Message.error({ background: true, content: "Error while fetching AmsPublished details." });
          });
      }
      this.rerenderLineItemConfiguration ^= 1;
      this.selectedCampaignLauncherConfiguration.id = 0;
    },
    isMediaPlanDataLoaded: function () {
      this.processing = !this.isMediaPlanDataLoaded;
    },
    isPromotionMode: {
      immediate: true,
      handler: function () {
        if (this.isPromotionMode) {
          this.selectedCampaignLauncherConfiguration.id = this.promotionObject.campaignLauncherConfiguration[this.selectedPublisher].id;
        }
      }
    },
    mappedLineItems: function () {
      this.set_lineItemLabels({});
      if (!Array.isArray(this.mappedLineItems) || this.isPromotionMode) {
        return;
      }

      let liIds = this.mappedLineItems.map(li => li.id).join(",");
      if (liIds.length > 0) {
        APIService.getLineItemLabels(liIds).then(response => {
          let lineItemLabels = {};
          response.data.result.forEach(label => {
            if (this.labelDimensions[label.labelTypeId]) {
              let ldName = this.labelDimensions[label.labelTypeId].name;
              lineItemLabels[ldName] = label;
            }
          })
          this.set_lineItemLabels(lineItemLabels);
        })
      }
    }
  }
};
</script>
<style scoped>
.lineitem-mapper-modal {
  height: 460px;
  overflow: auto;
}
.lineitem-mapper-modal span, .lineitem-mapper-modal label, .lineitem-mapper-modal input {
  font-size: 13px !important;
}
.lineitem-mapper-modal  span, .lineitem-mapper-modal label {
  color:#979898 !important;
}
.lineitem-mapper-modal .ivu-checkbox-checked .ivu-checkbox-inner {
  border-color:  var(--main-bg-color) !important;
  background-color: var(--main-bg-color) !important;
}
.lineitem-mapper-modal .ivu-checkbox-indeterminate .ivu-checkbox-inner{
  border-color:  var(--main-bg-color) !important;
  background-color: var(--main-bg-color) !important;
}
.lineitem-mapper-modal .ivu-radio-checked .ivu-radio-inner{
  border-color: var(--main-bg-color) !important;
}
.lineitem-mapper-modal .ivu-radio-checked .ivu-radio-inner::after{
  background-color: var(--main-bg-color) !important;
}
.launch-settings-square > button {
  width: 32px;
  height: 32px;
}
.promo-input {
  margin-bottom: 10px;
}
.promo-input .ivu-input-group-append {
  padding: 0px !important;
  margin-bottom: 10px !important;
}
.promo-input .btn-success {
  margin: 0px !important;
  width: 33px;
}
.cl-pull-left {
  padding: 0px !important;
  width: 40px !important;
}
</style>
<style  scoped>
.widget > hr {
  margin-top: 10px;
  margin-bottom: 10px;
}
.campaign-and-target > button {
  width: 138px;
  height: 32px;
}
.lineItemCountAndBudget{
  top: 8px;
}
</style>
