<template>
    <div :class="isEditableMediaPlan()?'editable-plan':'non-editable'">
       <div :class="{ 'enable-button-padding': !showLineItems }">
        <button
            type="button"
            class="btn create-btn btn-success button-style"
            v-on:click="enableHOTtable"
            v-show="!showLineItems"
            >Enable</button>
        </div>
         <hot-table ref="cinemaHot" v-show="showLineItems" :settings="settings"></hot-table>
           <div v-if="showSelectColumnsPopup">
            <select-columns
              :checkboxAttributeList="checkboxAttributeList"
              :checkboxBenchMarkList="benchMarkList"
              :selectedAttributes="selectedAttributes"
              :selectedBenchmarks="selectedBenchmarks"
              :benchmarksToBeDisabled="benchmarksToBeDisabled"
              @cancelActionForSelectColumns="cancelActionForSelectColumns"
              @addColumnIfCheckboxChecked="addColumnIfCheckboxChecked">
            </select-columns>
          </div>
          <div v-if="showSchedulerPopup">
           <scheduler
              :lineItems="lineItems"
              :mediaPlanName="mediaPlanName"
              :mediaPlanStartDate="mediaPlanStartDate"
              :mediaPlanEndDate="mediaPlanEndDate"
              @cancelActionForScheduler="cancelActionForScheduler"
              @saveSheduledSlots="saveSheduledSlots">
           </scheduler>
          </div>
          <div>
            <i-modal
            v-model="enableConfirmPopup"
            :footer-hide="false"
            :closable="false"
            :mask-closable="false"
            class-name="vertical-center-modal"
            >
            <h4 slot="header">
                {{ confirmBoxHeader }}
            </h4>
            <div>
              <p class="confirm-box-text-style">{{ confirmBoxText }}</p>
            </div>
            <div slot="footer">
                <button
                  type="button"
                  class="btn create-btn btn-white button-style"
                  v-on:click="confirmBoxCancel"
                  v-show="confirmBoxShowCancel"
                  >Cancel</button>
                <button
                  type="button"
                  class="btn create-btn btn-success button-style"
                  v-on:click="confirmBoxOk"
                  >OK</button>
            </div>
            </i-modal>
      </div>
    </div>
</template>
<script>
import { HotTable } from "@handsontable/vue";
import { Modal } from "iview";
import SelectColumns from "./SelectColumns.vue";
import Scheduler from "./Scheduler.vue";
import { ApiService } from ".././Services/mediaPlanBulkCreateApiService.js";
export default {
  components: {
    "i-modal": Modal,
    "hot-table": HotTable,
    "select-columns": SelectColumns,
    "scheduler": Scheduler
  },
  data: function () {
    return {
      lineItemData: [],
      attributeColumnMapper: {
        'Field 1': 'Market',
        'Field 2': 'Zone',
        'Field 3': 'Area',
        'Field 4': 'Duration'
      },
      selectedAttributes: [],
      selectedBenchmarks: [],
      benchmarksToBeDisabled: [],
      checkboxAttributeList: [
        { id: "startdate", name: "Segment Start Date" },
        { id: "enddate", name: "Segment End Date" }
      ],
      showSelectColumnsPopup: false,
      benchMarkColumns: [],
      attributeColumns: [],
      benchmarkToRemove: [],
      attributeToRemove: [],
      confirmBoxText: '',
      confirmBoxHeader: '',
      confirmBoxShowCancel: false,
      enableConfirmPopup: false,
      isDeleteEnabled: false,
      isEditRowDeleted: false,
      copiedLineItems: [],
      offlineMediaPlanColumns: [],
      offlineMediaPlanFixedColumns: [],
      lineItemDataParametersOrder: [],
      skipSettingMediaPlanDateFilter: false,
      showSchedulerPopup: false,
      lineItems: [],
      mediaPlanStartDate: '',
      mediaPlanEndDate: '',
      selectedLineItemDataParameters: [],
      mediaPlanName: '',
      pricingTypeMapping: [],
      validModel: false,
      offlineLineItems: [],
      deletedLIForEdit: [],
      showLineItems: false,
      apiService: {},
      mergedRow: [],
      selectedRange: [],
      bundleColumns: [],
      bundleIdsCount: new Map(),
      lineItemMode: 6
    }
  },
  async created () {
    this.apiService = new ApiService();
    this.offlineMediaPlanColumns = this.$parent.$parent.$parent.offlineMediaPlanColumns.map(column => ({ ...column }));
    this.offlineMediaPlanFixedColumns = this.$parent.$parent.$parent.offlineMediaPlanFixedColumns.map(column => ({ ...column }));
    this.offlineMediaPlanColumns.forEach(column => {
      if (column.title == "Channel") column.title = "Media Title";
      if (column.title == "Placement") column.title = "Description";
      if (column.title == "Segment") column.title = "Locations";
    });
    this.offlineMediaPlanFixedColumns.forEach(column => {
      if (column.title == "Rate Card Cost") column.title = "Special Rate";
    });
    this.lineItemPublishersAPIData = [];
    var response = await this.apiService.getPortalDetails();
    var portalId = response.data.data.id;
    this.publisherwhiteListEnabled = response.data.data.enablePublisherWhiteList == undefined ? false : response.data.data.enablePublisherWhiteList;
    this.staticPublishersEnabled = response.data.data.staticPublisherListEnabled == undefined ? false : response.data.data.staticPublisherListEnabled;
    if (this.staticPublishersEnabled) {
      try {
        var publishersRes = await this.apiService.getStaticLineItemPublishers(portalId);
        var filteredPublishers = [];
        if (publishersRes.data.publishers && publishersRes.data.publishers.length > 0) {
          filteredPublishers = publishersRes.data.publishers.filter(element =>
            element.lineItemModes &&
                  element.lineItemModes.split(',').map(mode => mode.trim()).includes("6")
          );
          filteredPublishers.forEach(element => {
            element.publisher = element.name
          });
        }
        this.lineItemPublishersAPIData = filteredPublishers;
        this.offlineMediaPlanColumns[2] = {
          title: "Media Title",
          data: "channel",
          type: "autocomplete",
          source: [],
          allowInvalid: false,
          wordWrap: false,
          minwidth: "150px",
          width: "150px",
          allowEmpty: false,
          filter: true
        };
        this.offlineMediaPlanColumns[2].source = this.lineItemPublishersAPIData.map(
          x => x.displayName
        );
      } catch (x) {
        console.log("Error occured in getting Media Title");
      }
    } else {
      try {
        var lineItemPublishersRes = await this.apiService.getLineItemPublishers();
        var filteredLineItemPublishers = []
        if (lineItemPublishersRes.data.publishers && lineItemPublishersRes.data.publishers.length > 0) {
          filteredLineItemPublishers = lineItemPublishersRes.data.publishers.filter(element => element.lineItemMode === 6);

          filteredLineItemPublishers.forEach(element => {
            element.name = element.publisher
          });
        }
        this.lineItemPublishersAPIData = filteredLineItemPublishers;
        this.offlineMediaPlanColumns[2] = {
          title: "Media Title",
          data: "channel",
          type: "dropdown",
          source: [],
          allowInvalid: false,
          wordWrap: false,
          minwidth: "150px",
          width: "150px",
          allowEmpty: false
        };
        this.offlineMediaPlanColumns[2].source = this.lineItemPublishersAPIData.map(
          x => x.displayName
        );
      } catch (x) {
        console.log("Error occured in getting channel type");
      }
    }
  },
  props: ["mediaPlanObject", "budgetCenterConfiguration", "dataParameterPricingTypeMapping", "benchMarkList", "lineItemTypes"],
  computed: {
    settings: function () {
      var self = this;
      return {
        data: [],
        height: 370,
        columns: {},
        colWidths: [200],
        afterChange: function (changes, source) {
          self.lineItemDataChange(changes, source)
        },
        manualColumnResize: true,
        selectionMode: 'multiple',
        mergeCells: [],
        manualRowResize: true,
        rowHeaderWidth: 25,
        invalidCellClassName: "invalid-cell-data",
        afterOnCellMouseDown: function (e, coords, TD) {
          if (coords.col === 0) {
            if ($(e.toElement).attr("id") == "delete-lineItem") {
              self.rowtoBeDeleted = coords.row;
              self.isDeleteEnabled = true;
              self.confirmBoxHeader = "Delete Line Item"
              self.confirmBoxShowCancel = true;
              self.confirmBoxText = 'Are you sure you want to delete?'
              self.enableConfirmPopup = true;
            }
          }
        },
        cells: function (row, col) {
          const cellPrp = {};
          if (col === 0) {
            cellPrp.readOnly = true;
            cellPrp.renderer = function (
              instance,
              td,
              row,
              col,
              prop,
              value,
              cellProperties
            ) {
              td.innerHTML =
                '<div><i class="fa fa-trash" id ="delete-lineItem" ></i></div>';
            };
          }
          return cellPrp;
        }
      }
    },
    hot: function () {
      return this.$refs.cinemaHot.hotInstance;
    }
  },
  methods: {
    init: function (intermediateSave) {
      this.addFieldColumns();
      var self = this;

      let pricingTypeMapping = [];
      let costSource = this.dataParameterPricingTypeMapping.find(costSource => costSource.id == 1);
      if (costSource) {
        pricingTypeMapping = costSource.lineItemPricing;
      }

      this.pricingTypeMapping = pricingTypeMapping;

      if (!intermediateSave) {
        self.offlineMediaPlanColumns[5].source = pricingTypeMapping.map(x => x.name);
        self.offlineMediaPlanColumns[6].source = pricingTypeMapping.length > 0 ? pricingTypeMapping[0].DataParameter.map(x => x.name) : [];
        this.benchmarksToBeDisabled = [];
      }

      if (this.$root.$children[0].mediaPlanId > 0) {
        self.lineItemData = [];
        this.attributeColumns = [];
        this.benchMarkColumns = [];
        let tabLineItems = self.$root.$children[0].offlineLineItems.filter(x => x.lineItemMode == this.lineItemMode);
        this.setDataToLineItems(this.convertToOfflineFormat(tabLineItems));

        if (tabLineItems.length > 0) {
          this.$nextTick(() => {
            this.hot.updateSettings({
              data: this.lineItemData,
              columns: [
                ...this.offlineMediaPlanColumns,
                ...this.bundleColumns,
                ...this.attributeColumns,
                ...this.benchMarkColumns,
                ...this.offlineMediaPlanFixedColumns
              ]
            })
          });
          this.showLineItems = true;
        } else {
          this.lineItemData = [];
          this.hot.updateSettings({
            data: this.lineItemData
          })
          this.showLineItems = false;
        }
      } else {
        this.lineItemData = [];
        this.hot.updateSettings({
          data: this.lineItemData
        })
        this.showLineItems = false;
      }
    },
    convertToOfflineFormat (lineItems) {
      return lineItems.map(li => {
        let buyTypeObj = this.pricingTypeMapping.find(x => x.id == li.pricingTypeReferenceValueId);
        let buyMetricObj = buyTypeObj.DataParameter.find(x => x.id == li.pricingTypeId);

        return {
          id: li.id,
          channel: li.publisher,
          placement: li.theme,
          segment: li.creativeFormat,
          buyType: buyTypeObj.name,
          buyMetric: buyMetricObj.name,
          perUnitPrice: li.perUnitPrice,
          orderId: li.orderId,
          lineItemId: li.id,
          startDate: li.startDate,
          endDate: li.endDate,
          lineItemScheduledSlots: JSON.parse(li.costPacingJson || "[]"),
          field1: (li.field1 || null),
          field2: (li.field2 || null),
          field3: (li.field3 || null),
          field4: (li.field4 || null),
          field5: (li.field5 || null),
          field6: (li.field6 || null),
          field7: (li.field7 || null),
          field8: (li.field8 || null),
          field9: (li.field9 || null),
          field10: (li.field10 || null),
          bundleId: li.bundleId,
          bundleCost: li.bundleCost,
          bundleDiscount: li.bundleDiscount,
          lineItemDataParameters: li.lineItemDataParameters,
          budget: li.budget,
          roId: li.roId,
          lineItemMode: this.lineItemMode,
          supplierId: (this.lineItemPublishersAPIData.find(publisher => publisher.displayName == li.channel) || {}).supplierId
        }
      });
    },
    getTableSourceData () {
      const tableData = this.hot.getSourceData();
      var bundleIdsChannel = new Map();
      var bundleIdsCost = new Map();
      var bundleIdsDiscount = new Map();
      tableData.forEach(li => {
        if (!(li.bundleId == undefined || li.bundleId == null || li.bundleId == 0) && (li.channel)) {
          if (!bundleIdsChannel.has(li.bundleId)) {
            bundleIdsChannel.set(li.bundleId, li.channel);
          }
        }
        if (!(li.bundleId == undefined || li.bundleId == null || li.bundleId == 0) && (li.bundleCost)) {
          if (!bundleIdsCost.has(li.bundleId)) {
            bundleIdsCost.set(li.bundleId, li.bundleCost);
          }
        }
        if (!(li.bundleId == undefined || li.bundleId == null || li.bundleId == 0) && (li.bundleDiscount)) {
          if (!bundleIdsDiscount.has(li.bundleId)) {
            bundleIdsDiscount.set(li.bundleId, li.bundleDiscount);
          }
        }
      });
      tableData.forEach(li => {
        if (li.bundleId && bundleIdsChannel.has(li.bundleId)) {
          li.channel = bundleIdsChannel.get(li.bundleId);
        }
        if (li.bundleId && bundleIdsCost.has(li.bundleId)) {
          li.bundleCost = bundleIdsCost.get(li.bundleId);
        }
        if (li.bundleId && bundleIdsDiscount.has(li.bundleId)) {
          li.bundleDiscount = bundleIdsDiscount.get(li.bundleId);
        }
      })
      return tableData;
    },
    getRowSourceData (row) {
      const tableData = this.hot.getSourceData();
      var bundleIdsChannel = new Map();
      var bundleIdsCost = new Map();
      var bundleIdsDiscount = new Map();
      tableData.forEach(li => {
        if (!(li.bundleId == undefined || li.bundleId == null || li.bundleId == 0) && (li.channel)) {
          if (!bundleIdsChannel.has(li.bundleId)) {
            bundleIdsChannel.set(li.bundleId, li.channel);
          }
          if (!bundleIdsCost.has(li.bundleId)) {
            bundleIdsCost.set(li.bundleId, li.bundleCost);
          }
          if (!bundleIdsDiscount.has(li.bundleId)) {
            bundleIdsDiscount.set(li.bundleId, li.bundleDiscount);
          }
        }
      });

      const rowData = this.hot.getSourceDataAtRow(row);

      if (rowData.bundleId && bundleIdsChannel.has(rowData.bundleId)) {
        rowData.channel = bundleIdsChannel.get(rowData.bundleId);
      }

      if (rowData.bundleId && bundleIdsCost.has(rowData.bundleId)) {
        rowData.bundleCost = bundleIdsCost.get(rowData.bundleId);
      }

      if (rowData.bundleId && bundleIdsDiscount.has(rowData.bundleId)) {
        rowData.bundleDiscount = bundleIdsDiscount.get(rowData.bundleId);
      }
      return rowData;
    },
    addRows: function () {
      let totalRows = this.hot.countRows();
      this.hot.alter("insert_row", totalRows, 1);
      let insertedRowData = this.getRowSourceData(totalRows);
      insertedRowData.orderId = this.getOrderId(insertedRowData);
      insertedRowData.mediaPlanVersionId = 0;
      insertedRowData.startdate = moment(this.$root.$children[0].mediaPlanObj.dateFilters[0]).format("MMM DD,YYYY");
      insertedRowData.enddate = moment(this.$root.$children[0].mediaPlanObj.dateFilters[1]).format("MMM DD,YYYY");
      insertedRowData.lineItemScheduledSlots = [];
    },
    confirmBoxCancel: function () {
      var self = this;
      self.enableConfirmPopup = false
      if (self.isDeleteEnabled) {
        self.isDeleteEnabled = false;
      }
    },
    confirmBoxOk: function () {
      var self = this;
      if (self.isDeleteEnabled) {
        var rowData = this.getRowSourceData(self.rowtoBeDeleted)

        if (rowData.id != 0 && rowData.id != undefined && rowData.id != "") {
          self.isEditRowDeleted = true;
          self.deletedLIForEdit.push(rowData);
          this.hot.alter("remove_row", self.rowtoBeDeleted);
        } else {
          this.hot.alter("remove_row", self.rowtoBeDeleted);
        }
        self.isDeleteEnabled = false;
        self.enableConfirmPopup = false;
        this.$emit("calculateLineItemTotalBudget");
      }
    },
    selectColumnPopUpEnable: function () {
      this.showSelectColumnsPopup = true;
      this.selectedBenchmarks = this.benchMarkColumns.map(
        item => item.title
      );
      this.selectedAttributes = this.attributeColumns.map(item => item.title);

      var disableSelectedBenchmark = [];
      let rowsCount = this.hot.countRows();
      for (let eachRow = 0; eachRow < rowsCount; eachRow++) {
        let rowData = this.getRowSourceData(eachRow);
        if (!disableSelectedBenchmark.includes(rowData.buyMetric)) {
          this.benchmarksToBeDisabled.push(rowData.buyMetric);
        }
      }
    },
    cancelActionForSelectColumns: function () {
      this.showSelectColumnsPopup = false;
    },
    addColumnIfCheckboxChecked: function (selectColumnsObject) {
      this.benchmarkToRemove = selectColumnsObject.benchmarkToRemove;
      selectColumnsObject.benchmarkToRemove.forEach(element => {
        this.benchMarkColumns = this.benchMarkColumns.filter(
          x => x.title != element
        );
      });

      this.attributeToRemove = selectColumnsObject.attributeToRemove;
      selectColumnsObject.attributeToRemove.forEach(element => {
        this.attributeColumns = this.attributeColumns.filter(
          x => x.title != element
        );
      });

      this.benchMarkColumns = [];
      this.selectedBenchmarks = selectColumnsObject.selectedBenchmarks;

      this.pushIntoBenchmarkColumns(selectColumnsObject.selectedBenchmarks);
      this.pushIntoAttributeColumns(selectColumnsObject.selectedAttributes);

      this.benchmarkToRemove = [];
      this.attributeToRemove = [];
      this.hot.updateSettings({
        columns: [
          ...this.offlineMediaPlanColumns,
          ...this.bundleColumns,
          ...this.attributeColumns,
          ...this.benchMarkColumns,
          ...this.offlineMediaPlanFixedColumns
        ]
      });

      this.showSelectColumnsPopup = false;
    },
    pushIntoBenchmarkColumns: function (benchMarks) {
      benchMarks.forEach(element => {
        if (
          !this.offlineMediaPlanColumns.map(x => x.title).includes(element) &&
          !this.offlineMediaPlanFixedColumns.map(x => x.title).includes(element) &&
          !this.attributeColumns.map(x => x.title).includes(element) &&
          !this.benchMarkColumns.map(x => x.title).includes(element)
        ) {
          this.benchMarkColumns.push({
            title: element,
            data: element,
            type: "numeric",
            minwidth: "100px",
            width: "100px",
            allowEmpty: true,
            numericFormat: {
              pattern: {
                thousandSeparated: true
              }
            }
          });
        }
      });
    },
    pushIntoAttributeColumns: function (attributes) {
      attributes.forEach(element => {
        if (
          !this.offlineMediaPlanColumns.map(x => x.title).includes(element) &&
          !this.offlineMediaPlanFixedColumns.map(x => x.title).includes(element) &&
          !this.attributeColumns.map(x => x.title).includes(element) &&
          !this.benchMarkColumns.map(x => x.title).includes(element)
        ) {
          if (element != undefined) {
            if (element == "Segment Start Date" || element == "Segment End Date") {
              this.attributeColumns.push({
                title: element,
                data: this.checkboxAttributeList.find(
                  x => x.name == element
                ).id,
                minwidth: "150px",
                type: "date",
                dateFormat: "MMM DD, YYYY",
                width: "150px",
                allowEmpty: true,
                datePickerConfig: {
                  minDate: moment(
                    this.$root.$children[0].mediaPlanObj.dateFilters[0]
                  ).toDate(),
                  maxDate: moment(
                    this.$root.$children[0].mediaPlanObj.dateFilters[1]
                  ).toDate()
                }
              });
            } else {
              this.attributeColumns.push({
                title: element,
                data: this.checkboxAttributeList.find(
                  x => x.name == element
                ).id,
                minwidth: "100px",
                width: "100px",
                wordWrap: false,
                allowEmpty: true
              });
            }

            if (element == "Segment Start Date" || element == "Segment End Date") {
              if (
                this.$root.$children[0].mediaPlanObj.dateFilters[0] != null &&
              this.$root.$children[0].mediaPlanObj.dateFilters[0] != ""
              ) {
                let rowsCount = this.hot.countRows();
                for (let eachRow = 0; eachRow < rowsCount; eachRow++) {
                  let rowData = this.getRowSourceData(eachRow);
                  if (element == "Segment Start Date") {
                    if (rowData.startdate != undefined) {
                      this.hot.setDataAtRowProp(
                        eachRow,
                        "startdate",
                        moment(rowData.startdate).format("MMM DD, YYYY")
                      );
                    } else {
                      this.hot.setDataAtRowProp(
                        eachRow,
                        "startdate",
                        moment(
                          this.$root.$children[0].mediaPlanObj.dateFilters[0]
                        ).format("MMM DD, YYYY")
                      );
                    }
                  }
                  if (element == "Segment End Date") {
                    if (rowData.enddate != undefined) {
                      this.hot.setDataAtRowProp(
                        eachRow,
                        "enddate",
                        moment(rowData.enddate).format("MMM DD, YYYY")
                      );
                    } else {
                      this.hot.setDataAtRowProp(
                        eachRow,
                        "enddate",
                        moment(
                          this.$root.$children[0].mediaPlanObj.dateFilters[1]
                        ).format("MMM DD, YYYY")
                      );
                    }
                  }
                }
              }
            }
          }
        }
      });
    },
    schedulerPopupEnable: function () {
      this.showSchedulerPopup = true;
      this.lineItems = this.getTableSourceData();
      this.mediaPlanName = this.$root.$children[0].mediaPlanObj.mediaPlanName;
      this.mediaPlanStartDate = moment(this.$root.$children[0].mediaPlanObj.dateFilters[0]).format("YYYY-MM-DD");
      this.mediaPlanEndDate = moment(this.$root.$children[0].mediaPlanObj.dateFilters[1]).format("YYYY-MM-DD");
    },
    cancelActionForScheduler: function () {
      this.showSchedulerPopup = false;
    },
    saveSheduledSlots: function (lineItemData) {
      this.lineItemData = lineItemData;
      this.showSchedulerPopup = false;
    },
    getOrderId: function (rowData) {
      let allData = this.getTableSourceData();
      allData = allData.concat(this.deletedLIForEdit);
      if (rowData.orderId == undefined || rowData.orderId == null) {
        let orderIds = allData.filter(x => x.orderId != undefined || x.orderId != null).map(x => x.orderId);
        if (orderIds.length == 0) {
          return 1;
        } else {
          return (Math.max.apply(Math, orderIds.map(x => x)) + 1);
        }
      } else {
        return rowData.orderId;
      }
    },
    lineItemDataChange: function (changes, source) {
      if (source == "CopyPaste.paste" || source == "edit" || source == "Autofill.fill" || source == "Custom.Computation") {
        this.$parent.$parent.$parent.isCinemaLineItemsValid = true;
        changes.forEach(([row, prop, oldValue, newValue]) => {
          let li = this.getRowSourceData(row);
          this.createDataForLineItems(li, row, prop, source);
        });
        if (this.copiedLineItems.length != 0) {
          this.setDataToLineItems(this.copiedLineItems);
          this.setDateRange();
        }
        this.copiedLineItems = [];
        if (this.hot != null) {
          this.$emit("calculateLineItemTotalBudget");
        }
      }
    },
    createDataForLineItems: function (li, row, prop, source) {
      var self = this;
      var mediaPlanStartDate = self.$root.$children[0].mediaPlanObj.dateFilters[0];
      var mediaPlanEndDate = self.$root.$children[0].mediaPlanObj.dateFilters[1];
      let lineItemRow = {
        channel: li.channel ? li.channel.replace(/[\t\n]/gm, ' ').trim() : "",
        placement: li.placement ? li.placement.replace(/[\t\n]/gm, ' ').trim() : "",
        segment: li.segment ? li.segment.replace(/[\t\n]/gm, ' ').trim() : "",
        id: li.lineItemId ? li.lineItemId : 0,
        buyType: li.buyType ? li.buyType : li.buyMetric ? this.pricingTypeMapping.map(x => x.name)[0] : li.buyType,
        buyMetric: li.buyMetric ? li.buyMetric : li.buyType ? this.pricingTypeMapping.filter(x => x.name == li.buyType)[0].DataParameter.map(x => x.name)[0] : li.buyMetric,
        perUnitPrice: li.perUnitPrice,
        budget: li.budget,
        mediaPlanVersionId: li.mediaPlanVersionId,
        orderId: li.orderId ? li.orderId : li.orderId = self.getOrderId(li),
        startDate: li.startdate == undefined || li.startdate == "" || li.startdate == null ? moment(mediaPlanStartDate).format("MMM DD, YYYY") : moment(li.startdate).format("MMM DD, YYYY"),
        endDate: li.enddate == undefined || li.enddate == "" || li.enddate == null ? moment(mediaPlanEndDate).format("MMM DD, YYYY") : moment(li.enddate).format("MMM DD, YYYY"),
        lineItemScheduledSlots: li.lineItemScheduledSlots,
        field1: (li.field1 || null),
        field2: (li.field2 || null),
        field3: (li.field3 || null),
        field4: (li.field4 || null),
        field5: (li.field5 || null),
        field6: (li.field6 || null),
        field7: (li.field7 || null),
        field8: (li.field8 || null),
        field9: (li.field9 || null),
        field10: (li.field10 || null),
        bundleId: li.bundleId,
        bundleCost: li.bundleCost,
        bundleDiscount: li.bundleDiscount,
        roId: li.roId,
        lineItemMode: this.lineItemMode,
        supplierId: (this.lineItemPublishersAPIData.find(publisher => publisher.displayName == li.channel) || {}).supplierId
      }
      // need to revisit this method once the api changes are done
      self.setCostValue(li, prop, lineItemRow);
      self.setLineItemDataParameters(li, prop, lineItemRow);
      if (self.copiedLineItems.map(x => x.orderId).includes(lineItemRow.orderId)) {
        self.copiedLineItems = self.copiedLineItems.filter(x => x.orderId != lineItemRow.orderId)
      }

      self.copiedLineItems.push(lineItemRow);
      self.selectedLineItemDataParameters = [];
      if (prop == "startdate") {
        this.skipSettingMediaPlanDateFilter = false;
      }
      if (prop == "enddate") {
        this.skipSettingMediaPlanDateFilter = false;
      }
    },
    setLineItemDataParameters: function (li, prop, lineItemRow) {
      var self = this;
      if (lineItemRow.buyMetric != undefined && lineItemRow.buyMetric != "" && lineItemRow.buyMetric != null) {
        self.selectedLineItemDataParameters.push({
          dataParameterId: self.benchMarkList.find(
            x => x.name == lineItemRow.buyMetric
          ).id,
          dataParameterValue: parseInt(li[li.buyMetric]) >= 0 && (li[li.buyMetric] > 0 || li[li.buyMetric] == 0) ? Math.round(li[li.buyMetric]) : ""
        })
      }

      var lineItemRowKeys = Object.keys(li);
      lineItemRowKeys.forEach(element => {
        var isPresent = self.benchMarkList.map(x => x.name).includes(element)
        if (isPresent && element != li.buyMetric) {
          if (!self.selectedLineItemDataParameters.map(x => x.dataParameterId)
            .includes(self.benchMarkList.find(x => x.name == element).id)) {
            if (li[element] != null) {
              self.selectedLineItemDataParameters.push({
                dataParameterId: self.benchMarkList.find(x => x.name == element).id,
                dataParameterValue: parseInt(li[element]) >= 0 && (li[element] > 0 || li[element] == 0) ? li[element] > 0 ? parseFloat(li[element].toFixed(2)) : li[element] : ""
              })
            }
          }
        }
      })
      lineItemRow["lineItemDataParameters"] = self.selectedLineItemDataParameters
    },
    setCostValue: function (li, prop, lineItemRow) {
      // Budget = Rate * Total Quantity
      if (prop == "budget") {
        if (li.budget != undefined && li.budget != null && li.budget != "" && parseInt(li.budget) >= 0 && (li.budget == 0 || li.budget > 0) &&
            li[li.buyMetric] != undefined && li[li.buyMetric] != null && li[li.buyMetric] != "" && parseInt(li[li.buyMetric]) >= 0 && (li[li.buyMetric] == 0 || li[li.buyMetric] > 0)
        ) {
          lineItemRow.budget = (li.budget > 0 || li.budget == 0) && li.budget != "" ? Number(parseFloat(li.budget).toFixed(2)) : li.budget
          lineItemRow.perUnitPrice = Number(li[li.buyMetric]) > 0 ? li.perUnitPrice = Number(parseFloat(li.budget / li[li.buyMetric]).toFixed(2))
            : li.perUnitPrice
        }
        if (li.perUnitPrice != undefined && li.perUnitPrice != null && li.perUnitPrice != "" && parseInt(li.perUnitPrice) >= 0 && (li.perUnitPrice == 0 || li.perUnitPrice > 0) &&
              li.budget != undefined && li.budget != null && li.budget != "" && parseInt(li.budget) >= 0 && (li.budget == 0 || li.budget > 0)
        ) {
          lineItemRow.budget = (li.budget > 0 || li.budget == 0) && li.budget != "" ? Number(parseFloat(li.budget).toFixed(2)) : li.budget
          lineItemRow[li.buyMetric] = Number(li.perUnitPrice) > 0 ? li[li.buyMetric] = Number(parseFloat(li.budget / li.perUnitPrice).toFixed(2)) : li[li.buyMetric]
        }
      }

      if (prop == "perUnitPrice") {
        if (li[li.buyMetric] != undefined && li[li.buyMetric] != null && li[li.buyMetric] != "" && parseInt(li[li.buyMetric]) >= 0 && (li[li.buyMetric] == 0 || li[li.buyMetric] > 0) &&
          li.perUnitPrice != undefined && li.perUnitPrice != null && li.perUnitPrice != "" && parseInt(li.perUnitPrice) >= 0 && (li.perUnitPrice == 0 || li.perUnitPrice > 0)
        ) {
          lineItemRow.perUnitPrice = (li.perUnitPrice > 0 || li.perUnitPrice == 0) && li.perUnitPrice != "" ? Number(parseFloat(li.perUnitPrice).toFixed(2)) : li.perUnitPrice
          lineItemRow.budget = li.budget = Number(parseFloat(li.perUnitPrice * li[li.buyMetric]).toFixed(2))
        }
        if (li.perUnitPrice != undefined && li.perUnitPrice != null && li.perUnitPrice != "" && parseInt(li.perUnitPrice) >= 0 && (li.perUnitPrice == 0 || li.perUnitPrice > 0) &&
          li.budget != undefined && li.budget != null && li.budget != "" && parseInt(li.budget) >= 0 && (li.budget == 0 || li.budget > 0)
        ) {
          lineItemRow.perUnitPrice = (li.perUnitPrice > 0 || li.perUnitPrice == 0) && li.perUnitPrice != "" ? Number(parseFloat(li.perUnitPrice).toFixed(2)) : li.perUnitPrice
          lineItemRow[li.buyMetric] = Number(li.perUnitPrice) > 0 ? li[li.buyMetric] = Number(parseFloat(li.budget / li.perUnitPrice).toFixed(2)) : li[li.buyMetric]
        }
      }

      if (prop == "Spots") {
        if (li[li.buyMetric] != undefined && li[li.buyMetric] != null && li[li.buyMetric] != "" && parseInt(li[li.buyMetric]) >= 0 && (li[li.buyMetric] == 0 || li[li.buyMetric] > 0) &&
          li.budget != undefined && li.budget != null && li.budget != "" && parseInt(li.budget) >= 0 && (li.budget == 0 || li.budget > 0)
        ) {
          lineItemRow.perUnitPrice = li.perUnitPrice = Number(parseFloat(li.budget / li[li.buyMetric]).toFixed(2))
          lineItemRow[li.buyMetric] = Number(li.perUnitPrice) > 0 ? li[li.buyMetric] = Number(parseFloat(li.budget / li.perUnitPrice).toFixed(2)) : li[li.buyMetric]
        }

        if (li[li.buyMetric] != undefined && li[li.buyMetric] != null && li[li.buyMetric] != "" && parseInt(li[li.buyMetric]) >= 0 && (li[li.buyMetric] == 0 || li[li.buyMetric] > 0) &&
          li.perUnitPrice != undefined && li.perUnitPrice != null && li.perUnitPrice != "" && parseInt(li.perUnitPrice) >= 0 && (li.perUnitPrice == 0 || li.perUnitPrice > 0)
        ) {
          lineItemRow.budget = li.budget = Number(parseFloat(li.perUnitPrice * li[li.buyMetric]).toFixed(2))
          lineItemRow[li.buyMetric] = Number(li.perUnitPrice) > 0 ? li[li.buyMetric] = Number(parseFloat(li.budget / li.perUnitPrice).toFixed(2)) : li[li.buyMetric]
        }
      }
    },
    setDataToLineItems: function (lineItemObj) {
      var self = this;
      var lineItemOrderIds = lineItemObj.map(x => x.orderId);
      lineItemOrderIds.sort(function (a, b) {
        return a - b;
      });
      lineItemOrderIds.forEach(liOrderId => {
        lineItemObj.forEach(li => {
          if (liOrderId == li.orderId) {
            let lineItemRow = {
              id: li.id,
              mediaPlanVersionId: li.mediaPlanVersionId,
              channel: li.channel,
              placement: li.placement,
              segment: li.segment,
              buyType: li.buyType ? li.buyType : li.buyTypeId ? self.pricingTypeMapping.find(x => x.id == li.buyTypeId).name : li.buyType,
              buyMetric: li.buyMetric ? li.buyMetric : li.buyMetricId && li.buyTypeId ? self.pricingTypeMapping.filter(x => x.id == li.buyTypeId)[0].DataParameter.find(x => x.id == li.buyMetricId).name : li.buyMetric,
              perUnitPrice: li.perUnitPrice,
              orderId: li.orderId,
              lineItemId: li.id,
              startdate: moment(li.startDate).format("MMM DD, YYYY"),
              enddate: moment(li.endDate).format("MMM DD, YYYY"),
              lineItemScheduledSlots: li.lineItemScheduledSlots,
              field1: (li.field1 || null),
              field2: (li.field2 || null),
              field3: (li.field3 || null),
              field4: (li.field4 || null),
              field5: (li.field5 || null),
              field6: (li.field6 || null),
              field7: (li.field7 || null),
              field8: (li.field8 || null),
              field9: (li.field9 || null),
              field10: (li.field10 || null),
              bundleId: li.bundleId,
              bundleCost: li.bundleCost,
              bundleDiscount: li.bundleDiscount,
              roId: li.roId,
              encodedId: this.generateLineItemEncodedId(li.id),
              lineItemMode: this.lineItemMode,
              supplierId: (this.lineItemPublishersAPIData.find(publisher => publisher.displayName == li.channel) || {}).supplierId
            }

            li.lineItemDataParameters.forEach(lidp => {
              let dataParameterName = self.benchMarkList.find(
                x => x.id == lidp.dataParameterId
              ).name;
              if (
                !self.offlineMediaPlanColumns.map(x => x.title).includes(dataParameterName) &&
                !self.offlineMediaPlanFixedColumns.map(x => x.title).includes(dataParameterName) &&
                !self.attributeColumns.map(x => x.title).includes(dataParameterName) &&
                !self.benchMarkColumns.map(x => x.title).includes(dataParameterName)
              ) {
                self.benchMarkColumns.push({
                  title: dataParameterName,
                  data: dataParameterName,
                  type: "numeric",
                  width: "100px",
                  minwidth: "100px",
                  allowEmpty: true,
                  numericFormat: {
                    pattern: {
                      thousandSeparated: true
                    }
                  }
                });
              }
              lineItemRow[dataParameterName] =
                    lidp.dataParameterValue == null ? 0 : lidp.dataParameterValue;

              lineItemRow.budget = li.budget != undefined && li.budget != null
                ? li.budget
                : (li.buyMetric != undefined && li.buyMetric != null && li.buyMetric != "" && parseInt(li.buyMetric) >= 0 && (li.buyMetric > 0 || li.buyMetric == 0) &&
                  li.perUnitPrice != undefined && li.perUnitPrice != null && li.perUnitPrice != "" && parseInt(li.perUnitPrice) >= 0 && (li.perUnitPrice > 0 || li.perUnitPrice == 0))
                  ? lineItemRow[dataParameterName] * li.perUnitPrice
                  : li.budget
            });

            let mediaPlanStartDate = moment(this.$root.$children[0].mediaPlanObj.dateFilters[0]);
            let mediaPlanEndDate = moment(this.$root.$children[0].mediaPlanObj.dateFilters[1]);

            if (li.startDate &&
              mediaPlanStartDate.diff(li.startDate, 'days') != 0 &&
              !self.attributeColumns.map(x => x.title).includes('Segment Start Date')) {
              let startDateAtr = self.checkboxAttributeList.find(x => x.id === "startdate");

              self.attributeColumns.push({
                title: startDateAtr.name,
                data: startDateAtr.id,
                minwidth: "150px",
                type: "date",
                dateFormat: "MMM DD, YYYY",
                width: "150px",
                allowEmpty: true,
                datePickerConfig: {
                  minDate: moment(
                    this.$root.$children[0].mediaPlanObj.dateFilters[0]
                  ).toDate(),
                  maxDate: moment(
                    this.$root.$children[0].mediaPlanObj.dateFilters[1]
                  ).toDate()
                }
              });
            }

            if (li.endDate &&
              mediaPlanEndDate.diff(li.endDate, 'days') != 0 &&
              !self.attributeColumns.map(x => x.title).includes('Segment End Date')) {
              let endDateAtr = self.checkboxAttributeList.find(x => x.id === "enddate");

              self.attributeColumns.push({
                title: endDateAtr.name,
                data: endDateAtr.id,
                minwidth: "150px",
                type: "date",
                dateFormat: "MMM DD, YYYY",
                width: "150px",
                allowEmpty: true,
                datePickerConfig: {
                  minDate: moment(
                    this.$root.$children[0].mediaPlanObj.dateFilters[0]
                  ).toDate(),
                  maxDate: moment(
                    this.$root.$children[0].mediaPlanObj.dateFilters[1]
                  ).toDate()
                }
              });
            }

            if (self.lineItemData.map(x => x.orderId).includes(lineItemRow.orderId)) {
              var index = self.lineItemData.map(x => x.orderId).indexOf(lineItemRow.orderId)
              self.lineItemData[index] = lineItemRow;
            } else {
              self.lineItemData.push(lineItemRow);
            }
          }
        })
      })

      this.hot.updateSettings({
        data: self.lineItemData,
        columns: [
          ...this.offlineMediaPlanColumns,
          ...this.bundleColumns,
          ...this.attributeColumns,
          ...this.benchMarkColumns,
          ...this.offlineMediaPlanFixedColumns
        ]
      })
    },
    generateLineItemEncodedId: function (lineItemId) {
      if (lineItemId == 0 || lineItemId == undefined || lineItemId == null) return "";
      return parseInt(deltax.businessProfileId).toString(36) + "-" + lineItemId + "L";
    },

    validateLineItems: async function () {
      return new Promise((resolve, reject) => {
        var lineItemData = this.getTableSourceData();
        // If the Cinema tab is not visited, then rendered rows will be -1

        let invalidCells = [];

        for (let rowIndex = 0; rowIndex < lineItemData.length; rowIndex++) {
          let cols = this.hot.getDataAtRow(rowIndex);
          let startDate = null;
          let endDate = null;
          let startDateIndex = 0;
          let endDateIndex = 0;

          for (let colIndex = 0; colIndex < cols.length; colIndex++) {
            let cellMeta = this.hot.getCellMeta(rowIndex, colIndex);
            if (cellMeta.prop.toUpperCase() == "STARTDATE") {
              startDate = cols[colIndex];
              startDateIndex = colIndex;
            } else if (cellMeta.prop.toUpperCase() == "ENDDATE") {
              endDate = cols[colIndex];
              endDateIndex = colIndex;
            }
          }

          if (startDate != null && endDate != null && moment(startDate) > moment(endDate)) {
            invalidCells.push({
              rowIndex,
              startDateIndex,
              endDateIndex
            });
          }
        }

        if (lineItemData.length > 0 && this.hot.countRenderedRows() > 0) {
          this.hot.validateCells(valid => {
            this.validModel = valid && (invalidCells.length == 0);

            for (let data of invalidCells) {
              this.hot.setCellMeta(data.rowIndex, data.startDateIndex, 'valid', false);
              this.hot.setCellMeta(data.rowIndex, data.endDateIndex, 'valid', false);
            }

            this.hot.render();
            resolve(valid && (invalidCells.length == 0))
          });
        } else {
          this.validModel = true;
          resolve(true)
        }
      })
    },
    saveLineItems: function () {
      var lineItemData = this.getTableSourceData();
      var self = this;
      if (self.isEditRowDeleted == true) {
        self.deletedLIForEdit.forEach(li => {
          if (!lineItemData.map(x => x.id).includes(li.id)) {
            li.IsDelete = 1;
            lineItemData.push(li);
          }
        });
      }

      if (lineItemData.map(x => x.IsDelete).includes(1)) {
        this.lineItemData = this.getTableSourceData().filter(x => x.IsDelete != 1);
        this.hot.updateSettings({
          data: this.lineItemData
        })
      }

      if (lineItemData.length > 0) {
        if (self.validModel) {
          return lineItemData.map(row => ({
            MediaPlanVersionId:
              this.$root.$children[0].mediaPlanId == 0
                ? 0
                : row.mediaPlanVersionId != undefined
                  ? row.mediaPlanVersionId
                  : 0,
            Id:
              this.$root.$children[0].mediaPlanId == 0
                ? 0
                : row.id != undefined
                  ? row.id
                  : 0,
            LineItemMode: this.lineItemMode,
            Publisher: row.channel,
            Theme: row.placement,
            CreativeFormat: row.segment,
            StartDate: row.startdate != undefined
              ? (moment(row.startdate).format("YYYY/MM/DD") <
                    moment(self.$root.$children[0].mediaPlanObj.dateFilters[0]).format("YYYY/MM/DD") ||
                    moment(row.startdate).format("YYYY/MM/DD") >
                    moment(self.$root.$children[0].mediaPlanObj.dateFilters[1]).format("YYYY/MM/DD"))
                ? moment(self.$root.$children[0].mediaPlanObj.dateFilters[0]).format("MMM DD,YYYY")
                : row.startdate
              : moment(self.$root.$children[0].mediaPlanObj.dateFilters[0]).format("MMM DD,YYYY"),
            EndDate: row.enddate != undefined
              ? (moment(row.enddate).format("YYYY/MM/DD") >
                    moment(self.$root.$children[0].mediaPlanObj.dateFilters[1]).format("YYYY/MM/DD") ||
                    moment(row.enddate).format("YYYY/MM/DD") <
                    moment(self.$root.$children[0].mediaPlanObj.dateFilters[0]).format("YYYY/MM/DD"))
                ? moment(self.$root.$children[0].mediaPlanObj.dateFilters[1]).format("YYYY/MM/DD")
                : row.enddate
              : moment(self.$root.$children[0].mediaPlanObj.dateFilters[1]).format("MMM DD,YYYY"),
            PricingTypeReferenceValueId: self.pricingTypeMapping.find(x => x.name == row.buyType).id,
            PricingTypeId: self.pricingTypeMapping.filter(x => x.name == row.buyType)[0].DataParameter.find(x => x.name == row.buyMetric).id,
            KpiMetricId: self.pricingTypeMapping.filter(x => x.name == row.buyType)[0].DataParameter.find(x => x.name == row.buyMetric).id,
            PerUnitPrice: row.perUnitPrice,
            Budget: row.budget,
            OrderId: row.id != undefined && row.id != 0 ? row.orderId : 0,
            Quantity: Math.round(row[row.buyMetric]),
            DataParameter: this.getDataParameters(this.benchMarkColumns, row),
            CostPacingJson: JSON.stringify(row.lineItemScheduledSlots),
            field1: (row.field1 || null),
            field2: (row.field2 || null),
            field3: (row.field3 || null),
            field4: (row.field4 || null),
            field5: (row.field5 || null),
            field6: (row.field6 || null),
            field7: (row.field7 || null),
            field8: (row.field8 || null),
            field9: (row.field9 || null),
            field10: (row.field10 || null),
            IsDelete: row.IsDelete ? 1 : 0,
            RoId: row.roId,
            SupplierId: (this.lineItemPublishersAPIData.find(publisher => publisher.displayName === row.channel) || {}).supplierId,
            BundleCost: row.bundleCost,
            BundleDiscount: row.bundleDiscount,
            BundleId: row.bundleId,
            type: this.lineItemTypes[0].id,
            costSource: 1,
            lineItemMode: this.lineItemMode,
            encodedId: row.id ? this.generateLineItemEncodedId(row.id) : null
          }));
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
    getDataParameters: function (benchMarkColumns, row) {
      if (benchMarkColumns != "" || benchMarkColumns != null) {
        var dataParameters = [];
        let orderIdIterator = 1;
        benchMarkColumns
          .filter(x => parseInt(row[x.data]) >= 0)
          .map(column => {
            if (row[column.data] == 0) {
              dataParameters.push({
                DataParameterId: this.benchMarkList.find(
                  x => x.name == column.data
                ).id,
                DataParameterValue: null,
                OrderId: 0
              });
            }
            if (row[column.data] > 0) {
              dataParameters.push({
                DataParameterId: this.benchMarkList.find(
                  x => x.name == column.data
                ).id,
                DataParameterValue: row[column.data],
                OrderId: orderIdIterator++
              });
            }
          });

        return dataParameters;
      } else {
      }
    },
    setDateRange: function () {
      var tableData = this.getTableSourceData();
      var startDates = tableData.map(x => new Date(moment(x.startdate).format("YYYY/MM/DD")));
      var endDates = tableData.map(x => new Date(moment(x.enddate).format("YYYY/MM/DD")));
      var minDate = moment(new Date(Math.min.apply(null, startDates))).format("YYYY/MM/DD")
      var maxDate = moment(new Date(Math.max.apply(null, endDates))).format("YYYY/MM/DD")
      if (minDate != 'Invalid date' && maxDate != 'Invalid date') {
        if (!this.skipSettingMediaPlanDateFilter) {
          if (moment(this.$root.$children[0].mediaPlanObj.dateFilters[0]).format("YYYY/MM/DD") > moment(minDate).format("YYYY/MM/DD") && moment(this.$root.$children[0].mediaPlanObj.dateFilters[1]).format("YYYY/MM/DD") >= moment(maxDate).format("YYYY/MM/DD")) {
            this.setMediaPlanObject(minDate, this.$root.$children[0].mediaPlanObj.dateFilters[1]);
            this.skipSettingMediaPlanDateFilter = true;
          }
          if (moment(this.$root.$children[0].mediaPlanObj.dateFilters[0]).format("YYYY/MM/DD") <= moment(minDate).format("YYYY/MM/DD") && moment(this.$root.$children[0].mediaPlanObj.dateFilters[1]).format("YYYY/MM/DD") < moment(maxDate).format("YYYY/MM/DD")) {
            this.setMediaPlanObject(this.$root.$children[0].mediaPlanObj.dateFilters[0], maxDate);
            this.skipSettingMediaPlanDateFilter = true;
          }
          if (moment(this.$root.$children[0].mediaPlanObj.dateFilters[0]).format("YYYY/MM/DD") > moment(minDate).format("YYYY/MM/DD") && moment(this.$root.$children[0].mediaPlanObj.dateFilters[1]).format("YYYY/MM/DD") < moment(maxDate).format("YYYY/MM/DD")) {
            this.setMediaPlanObject(minDate, maxDate);
            this.skipSettingMediaPlanDateFilter = true;
          }
        }
      }
    },
    setMediaPlanObject: function (startDate, endDate) {
      this.$root.$children[0].mediaPlanObj.dateFilters = [
        moment(startDate).format("MMM DD,YYYY"),
        moment(endDate).format("MMM DD,YYYY")
      ]
    },
    enableHOTtable: function () {
      var self = this;
      self.lineItemData = [];
      self.benchMarkColumns = [];
      self.attributeColumns = [];
      self.lineItemData.push({
        channel: null,
        placement: "",
        orderId: 1,
        mediaPlanVersionId: 0,
        lineItemScheduledSlots: [],
        startdate: moment(this.$root.$children[0].mediaPlanObj.dateFilters[0]).format("MMM DD,YYYY"),
        enddate: moment(this.$root.$children[0].mediaPlanObj.dateFilters[1]).format("MMM DD,YYYY")
      });

      self.hot.updateSettings({
        data: self.lineItemData,
        columns: [...self.offlineMediaPlanColumns, ...self.bundleColumns, ...self.offlineMediaPlanFixedColumns]
      });
      this.showLineItems = true;
    },
    isEditableMediaPlan: function () {
      if (this.$root.$children[0].isEstimateEnabled) {
        return false;
      } else {
        return true;
      }
    },
    addFieldColumns () {
      if (this.budgetCenterConfiguration != undefined && this.budgetCenterConfiguration != null && this.budgetCenterConfiguration != '' && this.budgetCenterConfiguration != {} && Object.keys(this.budgetCenterConfiguration).length > 0) {
        const cinemaConfig = this.budgetCenterConfiguration.find(
          (config) => config.type.toLowerCase() == "cinema"
        );
        if (cinemaConfig == undefined || cinemaConfig == null) {
          return;
        }
        const cinemaAlias = cinemaConfig.alias;
        if (cinemaAlias) {
          const isUnidentical = Object.entries(cinemaAlias).some(
            ([key, value]) => key !== value
          );
          if (isUnidentical) {
            Object.entries(cinemaAlias).forEach(([key, value]) => {
              const exists = this.checkboxAttributeList.some(item => item.id === key);
              if (!exists) {
                this.checkboxAttributeList.push({ id: key, name: this.attributeColumnMapper[value] || value });
              }
            });
          }
        }
      }
    }
  }
}
</script>
<style scoped>
.confirm-box-text-style{
  font-size: 13px;
  color: black;
}
.vertical-center-modal{
    display: flex;
    align-items: center;
    justify-content: center;
}
::v-deep .ivu-modal{
      top: 185px;
}
::v-deep .ivu-table td.readonly-class{
  background-color: rgb(245, 245, 245);
}
::v-deep .invalid-cell-data {
  border: 1px solid red;
}
.hide-element {
  visibility: hidden;
}
.enable-button-padding{
  padding-top: 10px;
}
::v-deep tr td.htDimmed{
  background: #f0f0f0 !important;
}
::v-deep .listbox .htDimmed{
  background: #ffffff !important;
}

</style>
