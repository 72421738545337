import { render, staticRenderFns } from "./PressLineItem.vue?vue&type=template&id=fb96eb7e&scoped=true&"
import script from "./PressLineItem.vue?vue&type=script&lang=js&"
export * from "./PressLineItem.vue?vue&type=script&lang=js&"
import style0 from "./PressLineItem.vue?vue&type=style&index=0&id=fb96eb7e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb96eb7e",
  null
  
)

export default component.exports